export const swiperTabsContent = [
  {
    tab: 'planCreation',
    tabTitle: 'Plan creation',
    imageName: 'plan creation',
    image: 'billsby-plan-creation.png',
    // srcSet:'plan-creation@2x.png 2x',
    popover: [
      {
        popoverContent: {
          title: 'Help and support',
          text: 'In-life help explains every option to make complex plan creation simple'
        }
      },
      {
        popoverContent: {
          title: 'Pricing models',
          url: '/product/creating/products-plans-cycles',
          buttonColor: 'orange',
          buttonText: 'Products, plans and cycles',
          list: [
            {
              text: 'Flat-fee'
            },
            {
              text: 'Per unit'
            },
            {
              text: 'Volume'
            },
            {
              text: 'Tiered'
            },
            {
              text: 'Ranged'
            },
          ]
        }
      },
      {
        popoverContent: {
          title: 'Advanced plan options',
          url: '/product/creating/trials-setup-contracts',
          buttonColor: 'orange',
          buttonText: 'Advanced billing options',
          text: 'Options like pro-rating, setup fees, free trials, variable billing dates and contract terms are all supported'
        }
      }
    ]
  },
  {
    tab: 'brandSettings',
    tabTitle: 'Brand settings',
    imageName: 'brand settings',
    image: 'billsby-branding-settings.png',
    // srcSet:'plan-creation@2x.png 2x',
    popover: [
      {
        popoverContent: {
          title: 'Custom copy',
          text: 'Change the text in emails we send and screens we display'
        },
      },
      {
        popoverContent: {
          title: 'Your brand, logo and colors',
          url: '/product/creating/brand-identity',
          buttonColor: 'orange',
          buttonText: 'Customise your brand identity',
          text: 'Every email, invoice and checkout screen inherits your brand identity'
        },
      },
      {
        popoverContent: {
          title: 'Add promotions',
          url: '/product/subscribers/invoices-creditnotes-emails',
          buttonColor: 'orange',
          buttonText: 'Invoices and emails',
          text: 'Insert adverts and promotions into your emails and invoices'
        }
      }
    ]
  },
  {
    tab: 'embedCode',
    tabTitle: 'Embed code',
    imageName: 'embed code',
    image: 'billsby-embed-code.png',
    // srcSet:'plan-creation@2x.png 2x',
    popover: [
      {
        popoverContent: {
          title: 'Basic embed code',
          url: '/product/subscribers/checkout-and-payments',
          buttonColor: 'orange',
          buttonText: 'Checkout and payments',
          text: 'Add Billsby to your website by copy and pasting two lines of code'
        },
      },
      {
        popoverContent: {
          title: 'Advanced options',
          text: 'Or use advanced options for deeper integrations with your website and customer data sync'
        }
      }
    ]
  },
  {
    tab: 'integratedCheckout',
    tabTitle: 'Integrated checkout',
    imageName: 'integrated checkout',
    // image: 'billsby-integrated-checkout.png',
    image: require("../images/new-checkout.png"),
    otherFormat: true,
    // srcSet:'plan-creation@2x.png 2x',
    popover: [
      {
        popoverContent: {
          title: 'Shopping cart',
          text: 'Allow customers to checkout multiple products and plans at the same time'
        },
      },
      {
        popoverContent: {
          title: 'Simplified PCI compliance',
          url: '/product/subscribers/checkout-and-payments',
          buttonColor: 'orange',
          buttonText: 'Checkout and payments',
          text: 'Never worry about storing customer card data on your server'
        },
      },
      {
        popoverContent: {
          title: 'Payment methods',
          url: '/product/payment-gateways',
          buttonColor: 'orange',
          buttonText: 'Payment gateways',
          text: 'Integrate with your chosen payment gateway to offer customers a wide variety of payment methods'
        }
      }
    ]
  },
  {
    tab: 'customerProfiles',
    tabTitle: 'Customer profiles',
    imageName: 'customer profiles',
    image: 'billsby-customer-profiles.png',
    // srcSet:'plan-creation@2x.png 2x',
    popover: [
      {
        popoverContent: {
          title: 'GDPR compliance',
          text: 'Let your customers be forgotten without impacting your reporting or revenue reconciliation'
        },
      },
      // {
      //   popoverContent: {
      //     title: 'Billsby Value Score',
      //     url: '/product/reporting/billsby-value-score',
      //     buttonColor: 'orange',
      //     buttonText: 'Billsby Value Score',
      //     text: 'Deeply understand your customers and differentiate your best and worst clients'
      //   },
      // },
      {
        popoverContent: {
          title: 'All your data in one place',
          url: '/product/integrations/customer-service',
          buttonColor: 'orange',
          buttonText: 'Customer service',
          text: 'Keep all of the information about customers together, add notes, make changes and even pull in data from other systems'
        }
      }
    ]
  },
  {
    tab: 'powerfulInvoices',
    tabTitle: 'Powerful invoices',
    imageName: 'powerful invoices',
    image: 'billsby-powerful-invoices.png',
    // srcSet:'plan-creation@2x.png 2x',
    popover: [
      {
        popoverContent: {
          title: 'Proactive alerting',
          text: 'Warn your clients of billing issues before they happen'
        },
      },
      {
        popoverContent: {
          title: 'Just-in-time support',
          text: 'Be there for your customers when they need you with the right contact details and information'
        },
      },
      {
        popoverContent: {
          title: 'Consolidated invoicing',
          url: '/product/subscribers/invoices-creditnotes-emails',
          buttonColor: 'orange',
          buttonText: 'Invoices and emails',
          text: "When customers have multiple plans, we'll invoice and charge them together when it makes sense to do so - reducing your gateway fees"
        }
      }
    ]
  },
  {
    tab: 'revenueProtection',
    tabTitle: 'Revenue protection',
    imageName: 'revenue protection',
    image: 'billsby-revenue-protection.png',
    // srcSet:'plan-creation@2x.png 2x',
    popover: [
      {
        popoverContent: {
          title: 'Custom email copy',
          text: 'Choose the copy of warning emails for each stage of your dunning journey, ensuring your customers are properly alerted and informed'
        },
      },
      {
        popoverContent: {
          title: 'Handle declined and failed payments differently',
          text: "Don't bucket customers who didn't pay properly due to a technical error at the bank with those who didn't have enough money - different failure types are treated independently by Billsby"
        },
      },
      {
        popoverContent: {
          title: 'Customizable dunning schedules',
          url: '/product/subscribers/dunning-retention',
          buttonColor: 'orange',
          buttonText: 'Dunning and retention',
          text: "Pick a dunning schedule that works for your business to maximise revenue recovery, and we'll automatically handle reattempts"
        }
      }
    ]
  },
  {
    tab: 'advancedReports',
    tabTitle: 'Advanced reports',
    imageName: 'advance reports',
    image: 'billsby-advanced-reports.png',
    // srcSet:'Billsby_Logo_Blue@2x.png 2x',
    popover: [
      {
        popoverContent: {
          title: 'Advanced reporting',
          url: '/product/reporting/reports-and-insight',
          buttonColor: 'orange',
          buttonText: 'Reports and insights',
          text: 'A wide variety of reports help you to better understand your business and analyze performance'
        }
      }
    ]
  },
  {
    tab: 'exceptionalServices',
    tabTitle: 'Exceptional service',
    imageName: 'exceptional service',
    image: 'billsby-exceptional-service.png',
    // srcSet:'Billsby_Logo_Blue@2x.png 2x',
    popover: [
      {
        popoverContent: {
          title: 'Still need help?',
          text: 'Our UK and US based support teams are on the case'
        }
      },
      {
        popoverContent: {
          title: 'Support community',
          url: 'https://support.billsby.com/discuss',
          buttonColor: 'orange',
          buttonText: 'Billsby Community',
          isExternal: true,
          text: 'Our support community helps you integrate Billsby into your website'
        }
      },
      {
        popoverContent: {
          title: 'Up-to-date documentation',
          url: 'https://support.billsby.com/docs',
          buttonColor: 'orange',
          buttonText: 'Billsby Documentation',
          isExternal: true,
          text: 'Well written and up-to-date documentation helps you to understand Billsby'
        }
      },
    ]
  },
]