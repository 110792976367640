import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import ScheduleADemoButton from "../components/schedule-a-demo-button"
import Img from "../components/image-transform"

const ComparisonTable = ({ content }) => {
  return (
    <div className="comparison-table">
      <div className="container">
        {/* <div className="content"> */}
        <div className="text-wrapper">
          <h2 className="title">{content.title}</h2>
          <p className="description">{content.description}</p>
        </div>

        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th className="tl"> </th>
                {content.heading.map((item, i) => {
                  return (
                    <th className="comparison-heading" key={i}>
                      <div className="image-holder">
                        {item.svgLogo ? (
                          <img
                            src={item.logo}
                            className="logo"
                            alt={item.title}
                          />
                        ) : (
                         <Img
                            filename={item.logo}
                            className="logo"
                            alt={item.title}
                          /> 
                        )}

                        {item.brandSize ? (
                          <p className="brand-text">{item.brandSize}</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </th>
                  )
                })}
              </tr>
            </thead>

            <tbody>
              {content.body.map((item, j) => {
                return (
                  <React.Fragment key={j}>
                    <tr className="mobile-title">
                      <td></td>
                      <td colSpan="5" className="row-title">
                        {item.title}
                        {item.subtitle ? (
                          <span className="row-subtitle">{item.subtitle}</span>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                    <tr className="compare-row">
                      <td className="row-title">
                        {item.title}
                        {item.subtitle ? (
                          <span className="row-subtitle">{item.subtitle}</span>
                        ) : (
                          ""
                        )}
                      </td>
                      {item.content.map((column, k) => {
                        return (
                          <td key={k}>
                            {column.subText ? (
                              <div className="has-sub">
                                {column.itemContent}
                                <span>{column.subText}</span>
                              </div>
                            ) : (
                              column.itemContent
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  </React.Fragment>
                )
              })}

              {content.buttons ? (
                <tr className="buttons">
                  <td></td>
                  <td>
                    {content.buttons.map((item, l) => {
                      return item.scheduleButton ? (
                        <ScheduleADemoButton
                          key={l}
                          buttonColor={item.buttonColor}
                        />
                      ) : item.isExternal ? (
                        <a
                          key={l}
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classNames(
                            "btn",
                            { "btn-orange": item.buttonColor === "orange" },
                            { "btn-white": item.buttonColor === "white" },
                            { "btn-black": item.buttonColor === "black" }
                          )}
                        >
                          {item.text}
                        </a>
                      ) : (
                        <Link
                          key={l}
                          to={item.url}
                          className={classNames(
                            "btn",
                            { "btn-orange": item.buttonColor === "orange" },
                            { "btn-white": item.buttonColor === "white" },
                            { "btn-black": item.buttonColor === "black" }
                          )}
                        >
                          {item.text}
                        </Link>
                      )
                    })}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
        {/* </div> */}
      </div>
    </div>
  )
}

export default ComparisonTable
